.sidebar.max{
  width: 250px;
}
.sidebar.min{
  width: 50px;
}

.sidebar{
  height: 100%;
  color:#dcdcdc;
  position:fixed;
  top:0;
  left: 0;
  float:left;
  clear:both;
  background:#1D2B36;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: 0.5s;
  z-index: 999;
  box-shadow:2px 2px 4px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow:2px 2px 4px rgba(0, 0, 0, 0.1);
}
.sidebar a {
  padding:10px 20px;
  text-decoration: none;
  color: #dcdcdc;
  display: inline-block;
  transition: 0.5s;
  text-align:left;
  float:left;
  clear:both;
  width:100%;
}
.module-title{
	display:block;
	font-size:18px;
	padding:19px 20px;
	line-height:normal;

}
.dashboard-title a{background:#0f161b;padding:15px 20px;text-transform:uppercase;}
.sidebar a img, .dashboard-title a svg{margin-right:12px;}
.module-title img{margin-right:10px;max-height:32px;}
.menu-title{padding:0;transition: 0.5s; }
.sidebar.max .menu-title{ display:inline-block;}
.sidebar.min .menu-title{display:none;}
.sidebar.min .dashboard-title .text{display: none;}
.sidebar.max .dashboard-title .text{display: inline-block;}
.sidebar.min .module-title .text{display: none;}
.sidebar.max .module-title .text{display: inline-block;}
.sidebar a:hover, .sidebar a:focus, .sidebar a.selected {
  color: #dcdcdc;
  background:#162029;
  overflow:hidden;
}
/* ul.submenu-list{
  display:none;
} */
